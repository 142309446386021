import { useContext, useEffect, useState } from "react";
import { Welcome } from "./sample/Welcome";
import { TeamsFxContext } from "./Context";
import { useData, useGraphWithCredential } from "@microsoft/teamsfx-react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import config from "./sample/lib/config";
import {
  FieldProps,
  Field,
  Input,
  Textarea,
  Button,
} from "@fluentui/react-components";
import { db } from "../util/db";
import { useLiveQuery } from "dexie-react-hooks";

const showFunction = Boolean(config.apiName);

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  const [currentUser, setCurrentUser] = useState("");
  const { teamsUserCredential } = useContext(TeamsFxContext);
  // const { loading, data, error } = useData(async () => {
  //   if (teamsUserCredential) {
  //     const userInfo = await teamsUserCredential.getUserInfo();
  //     console.log("token will be here");
  //     const token = await teamsUserCredential.getToken(
  //       "https://graph.microsoft.com/User.Read"
  //     );
  //     console.log("token is here", token);
  //     return userInfo.displayName;
  //   }
  // });

  const { loading, error, data, reload } = useGraphWithCredential(
    async (graph, teamsUserCredential, scope) => {
      // Call graph api directly to get user profile information
      const profile = await graph.api("/me").get();

      // Initialize Graph Toolkit TeamsFx provider
      const provider = new TeamsFxProvider(teamsUserCredential, scope);
      Providers.globalProvider = provider;
      Providers.globalProvider.setState(ProviderState.SignedIn);

      let photoUrl = "";
      try {
        const photo = await graph.api("/me/photo/$value").get();
        photoUrl = URL.createObjectURL(photo);
      } catch {
        // Could not fetch photo from user's profile, return empty string as placeholder.
      }
      console.log("prof", profile);
      //return { profile, photoUrl };
      return profile.displayName;
    },
    { scope: ["User.Read"], credential: teamsUserCredential }
  );

  const userName = loading
    ? "Loading User"
    : error
    ? "an error occured " + error
    : "Welcome, " + data;

  const [note, setNote] = useState({
    title: "",
    content: "",
  });

  const [screen, setScreen] = useState("list");

  useEffect(() => {
    if (screen === "new") setNote({ title: "", content: "" });
  }, [screen]);

  const notes = useLiveQuery(() => db.notes.toArray());

  const saveNote = async () => {
    try {
      // Add the new friend!
      const id = await db.notes.add({
        title: note.title,
        content: note.content,
        createTime: new Date(),
      });

      alert("added with id " + id);
    } catch (error) {
      alert("error occured while adding" + error);
    }
    setScreen("list");
  };

  const deleteNote = async (id) => {
    try {
      await db.notes.delete(id);
    } catch (error) {
      alert("error occured while removing" + error);
    }
  };

  return (
    <div
      className={
        themeString === "default"
          ? "light"
          : themeString === "dark"
          ? "dark"
          : "contrast"
      }
    >
      <div style={{ margin: "0 auto", maxWidth: "500px", padding: "20px" }}>
        <div>{userName}</div>
        <div style={{ textAlign: "right", display: "block" }}>
          <Button
            appearance="secondary"
            onClick={() => {
              setScreen("new");
            }}
          >
            New Note
          </Button>
        </div>
        {screen === "list" && (
          <ul>
            {notes?.map((note) => (
              <li key={note.id}>
                {note.title}{" "}
                <a href="javascript:;" onClick={() => deleteNote(note.id)}>
                  Delete
                </a>
              </li>
            ))}
            {notes?.length === 0 && <li>Add some notes</li>}
          </ul>
        )}
        {screen === "new" && (
          <div>
            <Field label="Title">
              <Input
                value={note.title}
                onChange={(e) => {
                  setNote({ ...note, title: e.target.value });
                }}
              />
            </Field>
            <Field label="Content">
              <Textarea
                onChange={(e) => {
                  setNote({ ...note, content: e.target.value });
                }}
              />
            </Field>
            <Button
              appearance="primary"
              onClick={() => {
                saveNote();
              }}
            >
              Submit Note
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
