import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

console.log("procc", process.env);

serviceWorkerRegistration.register();

reportWebVitals();
